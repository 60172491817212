export const lawData = [
    {
        "name": "Association Française de l'Éclairage",
        "description": "L'AFE est une association dédiée à la promotion de l'éclairage de qualité en France. Elle propose des recommandations, des normes, et des publications sur les meilleures pratiques en matière d'éclairage.",
        "impacts": [
            "Normes et recommandations pour l'éclairage urbain",
            "Publications sur les impacts de l'éclairage sur l'environnement et la santé"
        ],
        "details": "L'AFE joue un rôle clé dans la normalisation et la promotion des meilleures pratiques en matière d'éclairage urbain en France.",
        "url": "https://www.afe-eclairage.fr/"
    },
    {
        "name": "Bulletin Officiel des Annonces des Marchés Publics (BOAMP)",
        "description": "Le BOAMP publie des annonces et des appels d'offres publics, y compris ceux concernant les projets d'éclairage urbain.",
        "impacts": [
            "Appels d'offres et projets en cours relatifs à l'éclairage urbain",
            "Exigences légales pour les marchés publics d'éclairage"
        ],
        "details": "Le BOAMP fournit un cadre légal et des informations clés pour les appels d'offres publics liés à l'éclairage urbain.",
        "url": "https://www.boamp.fr/pages/entreprise-accueil/"
    },
    {
        "name": "Ministère de la Transition Écologique",
        "description": "Ce site fournit des informations sur les politiques environnementales et écologiques du gouvernement français, y compris les réglementations sur la pollution lumineuse.",
        "impacts": [
            "Réglementations et décrets sur la pollution lumineuse",
            "Politiques gouvernementales sur l'efficacité énergétique de l'éclairage urbain"
        ],
        "details": "Le Ministère de la Transition Écologique définit les réglementations visant à réduire la pollution lumineuse et à promouvoir l'efficacité énergétique dans l'éclairage urbain.",
        "url": "https://www.ecologie.gouv.fr/"
    },
    {
        "name": "ADEME (Agence de la transition écologique)",
        "description": "L'ADEME soutient les projets de transition écologique et énergétique. Elle fournit des guides, des études et des financements pour des projets d'éclairage durable.",
        "impacts": [
            "Guides pratiques pour l'éclairage public durable",
            "Études de cas et exemples de projets réussis"
        ],
        "details": "L'ADEME est une ressource essentielle pour les collectivités et entreprises qui souhaitent mettre en œuvre des projets d'éclairage public écologiques et durables.",
        "url": "https://www.ademe.fr/"
    },
    {
        "name": "Commission Internationale de l'Éclairage (CIE)",
        "description": "La CIE est une organisation mondiale qui établit des normes pour tous les aspects de l'éclairage, y compris l'éclairage urbain.",
        "impacts": [
            "Normes internationales de l'éclairage",
            "Recherches et publications sur les impacts de l'éclairage"
        ],
        "details": "La CIE définit les standards internationaux de l'éclairage, assurant une cohérence globale dans les pratiques d'éclairage urbain."
        ,
        "url": "https://cie.co.at/"
    },
    {
        "name": "ISO (Organisation Internationale de Normalisation)",
        "description": "L'ISO développe et publie des normes internationales, y compris celles concernant l'éclairage.",
        "impacts": [
            "Normes ISO pertinentes pour l'éclairage urbain",
            "Exigences de qualité et de sécurité pour les installations d'éclairage"
        ],
        "details": "Les normes ISO sont cruciales pour garantir la qualité et la sécurité des installations d'éclairage urbain.",
        "url": "https://www.iso.org/home.html"
    },
    {
        "name": "Ministère de la Justice",
        "description": "Le site du Ministère de la Justice fournit des informations sur les décisions judiciaires et les implications légales des lois.",
        "impacts": [
            "Décisions judiciaires pertinentes concernant l'éclairage urbain",
            "Interprétations légales des lois et règlements"
        ],
        "details": "Le Ministère de la Justice offre un aperçu des interprétations légales et des précédents judiciaires relatifs aux lois sur l'éclairage urbain.",
        "url": "https://www.justice.gouv.fr/justice-france/lorganisation-cours-tribunaux/lordre-judiciaire/cour-cassation"
    },
    {
        "name": "Pollution Lumineuse (Ministère de la Transition Écologique)",
        "description": "Cette page spécifique du ministère traite de la prévention et de la réduction de la pollution lumineuse.",
        "impacts": [
            "Mesures et recommandations pour réduire la pollution lumineuse",
            "Exigences pour l'éclairage public afin de minimiser l'impact environnemental"
        ],
        "details": "Le ministère définit des mesures spécifiques pour limiter l'impact environnemental de la pollution lumineuse dans les zones urbaines.",
        "url": "https://www.ecologie.gouv.fr/politiques-publiques/pollution-lumineuse"
    },
    {
        "name": "Service Public - Entreprendre",
        "description": "Ce site fournit des informations pour les entreprises sur la conformité aux réglementations, y compris celles relatives à l'éclairage public.",
        "impacts": [
            "Obligations légales pour les entreprises concernant l'éclairage urbain",
            "Procédures et guides pour se conformer aux régulations"
        ],
        "details": "Service Public - Entreprendre aide les entreprises à naviguer dans les exigences légales liées à l'éclairage urbain.",
        "url": "https://entreprendre.service-public.fr/vosdroits/F24396"
    },
    {
        "name": "Cahier d'Accompagnement Axe1 Éclairage",
        "description": "Document PDF fournissant des directives détaillées pour la mise en œuvre de projets d'éclairage public.",
        "impacts": [
            "Directives pratiques pour les projets d'éclairage urbain",
            "Stratégies pour améliorer l'efficacité énergétique et réduire l'impact environnemental"
        ],
        "details": "Le Cahier d'Accompagnement offre des stratégies concrètes pour optimiser les projets d'éclairage urbain.",
        "url": "https://www.ecologie.gouv.fr/sites/default/files/Cahier accompagnement_Axe1_Eclairage.pdf"
    },
    {
        "name": "Financement Travaux Collectivité (SIEDS)",
        "description": "Le SIEDS offre des solutions de financement pour les projets d'éclairage public des collectivités.",
        "impacts": [
            "Options de financement pour les projets d'éclairage public",
            "Exemples de projets financés et leurs impacts"
        ],
        "details": "SIEDS fournit des ressources financières pour aider les collectivités à réaliser des projets d'éclairage public efficaces.",
        "url": "https://www.sieds.fr/financement-travaux-collectivite/eclairage-public/"
    },
    {
        "name": "Trame Noire (OFB)",
        "description": "L'Office Français de la Biodiversité explique le concept de la trame noire, visant à réduire la pollution lumineuse pour protéger la biodiversité.",
        "impacts": [
            "Concepts et bénéfices de la trame noire",
            "Exigences pour la mise en place de zones de faible pollution lumineuse"
        ],
        "details": "La trame noire est une initiative pour protéger la biodiversité en minimisant l'éclairage dans certaines zones sensibles.",
        "url": "https://www.ofb.gouv.fr/trame-verte-et-bleue/trame-noire"
    },
    {
        "name": "Décret n°2018-1186 du 19 décembre 2018",
        "description": "Décret officiel sur la prévention et la réduction des nuisances lumineuses.",
        "impacts": [
            "Exigences légales spécifiques pour réduire les nuisances lumineuses",
            "Date de mise en application et impacts attendus"
        ],
        "details": "Ce décret impose des règles strictes pour minimiser les nuisances lumineuses, en particulier dans les zones urbaines sensibles.",
        "url": "https://www.legifrance.gouv.fr/loda/id/JORFTEXT000037864346"
    },
    {
        "name": "Arrêté du 27 décembre 2018",
        "description": "Arrêté détaillant les mesures spécifiques pour la prévention, la réduction et la limitation des nuisances lumineuses.",
        "impacts": [
            "Mesures détaillées pour limiter les nuisances lumineuses",
            "Obligations pour les collectivités et les entreprises"
        ],
        "details": "Cet arrêté précise les mesures que doivent prendre les collectivités pour se conformer aux nouvelles normes d'éclairage.",
        "url": "https://www.ecologie.gouv.fr/politiques-publiques/arrete-du-27-decembre-2018-relatif-prevention-reduction-limitation-nuisances"
    },
    {
        "name": "Charte Éclairage Public 2013 (SDE35)",
        "description": "Charte détaillant les bonnes pratiques pour l'éclairage public en 2013.",
        "impacts": [
            "Bonnes pratiques pour l'éclairage public",
            "Exigences et recommandations pour améliorer l'efficacité et réduire l'impact"
        ],
        "details": "La charte de 2013 offre des recommandations essentielles pour améliorer la durabilité et l'efficacité des systèmes d'éclairage urbain.",
        "url": "https://www.sde35.fr/sites/default/files/2020-04/Charte_Eclairage_Public_2013.pdf"
    }
]